// For Local Testing, change the URL to 'https://fundraisers.giveindia.in'
export const BASE_URL = process.env.REACT_APP_BASE_URI_FUNDRAISERS ? process.env.REACT_APP_BASE_URI_FUNDRAISERS : "https://qa1.give.do";
export const ECHO_SERVICE_URI = process.env.REACT_APP_ECHO_SERVICE_URI || 'https://echo.give.do';
export const SUBS_URL = process.env.REACT_APP_SUBSCRIPTION_BASE_URL_FUNDRAISERS ? process.env.REACT_APP_SUBSCRIPTION_BASE_URL_FUNDRAISERS : "https://www.giveindia.in";
export const googleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID_FUNDRAISERS ? process.env.REACT_APP_GOOGLE_CLIENT_ID_FUNDRAISERS : "143892047968-33vi24n389oh0ql7ho3nd69jhuaocsmu.apps.googleusercontent.com";
export const twitterHandle = "GiveIndia";
export const seotitle = "Give.do | India’s best online donation platform";
export const seoDescription = "Discover social causes and give easily to fundraisers by verified NGOs. Donate to causes related to children, elderly, animal, environment, etc. on give.do";
export const seoFundraisertitle = "Fundraising – Best Platform for Online Fundraising In India - Give";
export const seoFundraiserDescription = "Give - Largest online Fundraising platform in India. We are helping to raise fund online for various causes like medical, animal, education. Start a fundraiser now!";

//Fundraiser listing page meta title & description
export const seoFrTitle = 'Be The Change: Choose From 10,000+ Fundraisers on Give and Donate';
export const seoFrDescription = 'Welcome to Give! Explore 10,000+ fundraisers and make a change through your donation to end poverty and help people in need!';

export const SUPPORT_EMAIL_ID = process.env.REACT_APP_SUPPORT_EMAIL_FUNDRAISERS
export const AMPLITUDE_KEY = process.env.REACT_APP_AMPLITUDE_KEY_FUNDRAISERS || "dea3c1b7bbdad082820a8926f811d3a8"
export const AMP_DEPLOYMENT_KEY = process.env.REACT_APP_AMP_DEPLOYMENT_KEY_FUNDRAISERS || "client-YZtGe6KowwLZn3IyxcFOPWlseqQYhr3s"
export const AMP_DEPLOYMENT_KEY_HOMEPAGE_BANNER = process.env.REACT_APP_AMP_DEPLOYMENT_KEY_HOMEPAGE_BANNER_FUNDRAISERS || "client-jKohdCcFcPCAk5hb2yEzqvKtwaBIZ0wR"
export const AMP_DEPLOYMENT_KEY_SOCIAL_PROOF_V2 = "client-NjshEzRpMAodwXqva8gj6oz8qgVqOoOL"
export const EXPERIMENT_CAMPAIGN_ID = "6507dd6edc38361824a54cc1"
// export const AMP_DEPLOYMENT_KEY = process.env.REACT_APP_AMP_DEPLOYMENT_KEY || "client-qA3OHjp4rd7pcOKQxpl7vGidy4CnO5EA"

export const TITLE = "Give"
export const GIVE_LOGO = 'https://cfstatic.give.do/logo.png';
export const GIVE_TEAM_BANNER =  "https://cfstatic.give.do/f8a48f87-fb1d-45ba-ab41-9e1bb7bb4200.webp";
export const AMP_EXP_1 = 'matching-funds-test';
export const AMP_SOCIAL_PROOFING_EXP = 'social-proof-v2';
export const AMP_HOMEPAGE_BANNER_EXP = process.env.REACT_APP_HOMEPAGE_BANNER_EXP_FUNDRAISERS || 'homepage-banner-v2';
export const AMP_DEPLOYMENT_KEY_SOCIAL_PROOFING_EXP = "client-jR77I68nccNAuQ556W3G8OftlnaG6QEV"

export const GTM_WEB_ID = process.env.REACT_APP_GOOGLE_TAG_MANAGER_FUNDRAISERS
export const ANALYTICS_URI = process.env.REACT_APP_ANALYTICS_URI_FUNDRAISERS || 'https://letzchangetestfilesgivedo.s3.ap-southeast-1.amazonaws.com/analytics/staging/index.js'
export const MOENGAGE_APP_ID = process.env.REACT_APP_MOENGAGE_APP_ID_FUNDRAISERS || "O1SD9EMY8RML4XXEXYC1Q1MR_DEBUG"
export const SUBSCRIPTION_BASEURL = process.env.REACT_APP_SUBSCRIPTION_BASE_URI_FUNDRAISERS ? process.env.REACT_APP_SUBSCRIPTION_BASE_URI_FUNDRAISERS : `https://qa1.give.do/api/subscriptions/subscriptions`
export const TOKEN_FREQUENCY_BEFORE_FOR_DATES = process.env.PAYMENTS_SERVICE_TOKEN_FREQUENCY_BEFORE_FOR_DATES ? process.env.PAYMENTS_SERVICE_TOKEN_FREQUENCY_BEFORE_FOR_DATES.split(',').map(item => parseInt(item)) : [28,29,30,31]
export const MINIMUM_AMOUNT_TO_TRIGGER_MONTHLY_PAYMENT_US = 50;
export const MAXIMUM_AMOUNT_TO_TRIGGER_MONTHLY_PAYMENT_US = 100;
export const MINIMUM_AMOUNT_TO_TRIGGER_MONTHLY_PAYMENT_IN = 500;
export const MAXIMUM_AMOUNT_TO_TRIGGER_MONTHLY_PAYMENT_IN = 5000;

export const GIVE_NGO_ID = process.env.REACT_APP_GIVE_NGO_ID_FUNDRAISERS || '65119bb1a7036f968eed3484'

export const MINIMUM_DONATION_AMOUNT_INR = 50 // Donations should be more than or equal to 50 Rupees
export const MINIMUM_DONATION_AMOUNT_USD = 1  // Donations should be more than or equal to 1 USD
export const MINIMUM_MONTHLY_DONATION_INR = 500 // Donations of "MONTHLY" subscription type should be >= 500 Rupees
export const MINIMUM_MONTHLY_DONATION_USD = 10 // Donations of "MONTHLY" subscription type should be >= 10 USD
export const SUPERCAMPAIGN_URL='supercampaigns/'


